.testimonial {
    padding: 60px 0;
    text-align: center;
    width: 500px;
  }
  
  .testimonial-title {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 40px;
    text-transform: uppercase;
    color: #1d1d1f;
    font-weight: 700;
  }
  
  .stars {
    margin-bottom: 10px;
    color: var(--clr-1);
  }
  
  .testimonial-item {
    background-color: #1d1d1f;
    padding: 40px;
    border-radius: 5px;

  }
  
  .testimonial-item-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--clr-2);
    margin-bottom: 10px;
  }
  
  .testimonial-item-text {
    font-size: 1rem;
    color: #fff;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 600px) {
   .testimonial {
    width: 100%;
   }
  }