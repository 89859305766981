nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
  }
  
  .main-logo {
    font-size: 1.2rem;
    margin-right: auto;
    text-decoration: none;
    color: var(--clr-2);
    font-weight: 600;
  }
  
  .link-item {
    font-size: 0.9rem;
    text-decoration: none;
    margin: 0 8px;
    color: #4f4f4f;
  }

  .link-item:hover {
    color: rgb(204, 0, 0)
  }
  
  @media screen and (max-width: 600px) {
    nav {
      flex-direction: column;
    }
    .main-logo {
      margin: 10px auto;
    }
  }
  