.contact-page {
    padding: 40px 10px;
  }
  .contact {
    padding: 0;
  }
  
  .contact-title {
    color: #F20D5E;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    margin: 30px 0;
  }
  
  .contact-text {
    text-align: center;
    margin: 0;
  }
  
  .contact-header {
    display: block;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
  }