footer {
    background-color: var(--clr-5);
    padding: 40px 0;
  }
  
  .footer-item > p {
    color: var(--clr-1);
    font-size: 0.8rem;
    text-align: center;
  }
  
  .social {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-items: center;
  }
  
  .footer-logo {
    display: block;
    width: 40px;
  }
  
  
  .footer-group {
    display: grid;
    gap: 20px;
    justify-items: center;
  
    align-items: center;
  }
  
  .footer-main {
    text-decoration: none;
    color: var(--clr-2);
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  @media screen and (min-width: 600px) {
    .footer-group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        justify-items: center;
        align-items: center;
    }
  }
  
  .checked {
    color: #cabd8c;
  }
  