.blog-wrapper {
  padding: 60px 0;
}

.blog-wrapper h2 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 40px;
}

  .blog-container {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .blog-sidebar {
    background-color: #fff;
  }

  .blog-group {
    display: flex;
    flex-direction: column;
    gap: 20px;

  }

  .blog-item {
    text-decoration: none;
    width: 600px;
    margin: 0 auto;
  }

  .blog-title {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #F20D5E;
  }

  .blog-author {
    font-size: 0.9rem;
    color: #4c4c4c;
  }

  .blog-tags {
    color: #F20D5E;
    font-size: 0.8rem;
  }

  .blog-detail-container {
    padding: 60px 0;
    width: 600px;
    margin: 0 auto;
  }

  .blog-detail-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #F20D5E;
    margin: 10px 0;
  }

  .blog-detail-content {
    line-height: 1.5;
    margin: 20px 0;

  } 

  .blog-detail-author {
    font-weight: 500;
  } 

  .blog-detail-timeDate {
    font-size: 0.8rem;
  }

  .blog-detail-category {
      font-size: 0.8rem;
    }
  

  @media screen and (max-width: 768px) {
    .blog-item {
      width: 100%;
      padding: 10px;
    }
  }