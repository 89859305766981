.not-found {
    padding: 60px 0;
    text-align: center;
}
.not-found img {
    width: 500px;
}

.not-found h2 {
    font-size: 5rem;
    text-align: center;
    font-weight: 800;
    margin-top: 40px;
}

.not-found p {
    font-size: 1rem;
    text-align: center;
    margin-top: 20px;
}


@media screen and (max-width:600px) {
    .not-found img {
        width: 100%;
    }

    .not-found h2 {
        font-size: 2rem;
        text-align: center;
        font-weight: 800;
        margin-top: 40px;
    }
}